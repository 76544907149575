<template>
    <div class="myAccount modifyPassword">
        <div class="myAccount-title">
            <div class="back" @click="backClick">
                <img src="@/assets/images/personal/z.png" />
                <span>Back</span>
            </div>
            <h5>Modify Password</h5>
        </div>
        <div class="myAccount-main modifyPassword-main">
            <div>
                <p>Old Password</p>
                <div>
                    <input :type="oldPasswordType" v-model="oldPassword" @change="passwordChange" />
                    <img
                        v-show="!oldPasswordShow"
                        src="@/assets/images/personal/closeEyes.png"
                        @click="
                            oldPasswordType = 'text'
                            oldPasswordShow = true
                        "
                    />
                    <img
                        v-show="oldPasswordShow"
                        src="@/assets/images/personal/openEyes.png"
                        @click="
                            oldPasswordType = 'password'
                            oldPasswordShow = false
                        "
                    />
                </div>
            </div>
            <div>
                <p>New Password</p>
                <div>
                    <input :type="newPasswordType" v-model="newPassword" @change="passwordChange" />
                    <img
                        v-show="!newPasswordShow"
                        src="@/assets/images/personal/closeEyes.png"
                        @click="
                            newPasswordType = 'text'
                            newPasswordShow = true
                        "
                    />
                    <img
                        v-show="newPasswordShow"
                        src="@/assets/images/personal/openEyes.png"
                        @click="
                            newPasswordType = 'password'
                            newPasswordShow = false
                        "
                    />
                </div>
            </div>
            <div>
                <p>Confirm Password</p>
                <div>
                    <input :type="confirmPasswordType" v-model="confirmPassword" @change="passwordChange" />
                    <img
                        v-show="!confirmPasswordShow"
                        src="@/assets/images/personal/closeEyes.png"
                        @click="
                            confirmPasswordType = 'text'
                            confirmPasswordShow = true
                        "
                    />
                    <img
                        v-show="confirmPasswordShow"
                        src="@/assets/images/personal/openEyes.png"
                        @click="
                            confirmPasswordType = 'password'
                            confirmPasswordShow = false
                        "
                    />
                </div>
                <span v-show="confirmPassword != '' && newPassword != confirmPassword" style="color: #f56c6c">The two passwords are inconsistent!</span>
            </div>
        </div>
        <div class="modifyPassword-btn">
            <button :style="background" @click="btnClick">Submit</button>
        </div>

        <el-dialog :visible.sync="modifyPasswordShow" @closed="handleClose" width="23%" style="margin-top: 20vh">
            <ConfirmPassword @deteleNO="deleteNo" @deteleYes="deleteYes" :oldPassword="oldPassword" :newPassword="newPassword" :confirmPassword="confirmPassword"></ConfirmPassword>
        </el-dialog>

        <EditPasswordComplete v-show="yesShow"></EditPasswordComplete>
        <div class="dialogMask" v-show="yesShow"></div>
    </div>
</template>

<style>
.editPasswordDialog .el-dialog__headerbtn {
    display: none;
}
</style>
<style lang="scss" scoped>
@import "./css/myAccount.scss";
.modifyPassword {
    padding-bottom: 80px;

    .modifyPassword-main {
        > div {
            padding-bottom: 40px;

            > div {
                width: 100%;
                display: flex;
                height: 40px;
                align-items: center;
                position: relative;

                input {
                    width: 100%;
                    outline: none;
                    border: none;
                    border-bottom: 1px solid #d8dadc;
                    font-size: 14px;
                    font-family: Montserrat,SF-UI;
                    font-weight: 400;
                    color: #330000;
                    padding-right: 30px;
                }

                > img {
                    position: absolute;
                    right: 0;
                    cursor: pointer;
                }
            }
        }
    }

    .modifyPassword-btn {
        width: 100%;
        display: flex;
        justify-content: center;
        padding-top: 10px;

        button {
            outline: none;
            border: none;
            font-size: 16px;
            font-family: Montserrat,SF-UI;
            font-weight: 400;
            color: #ffffff;
            padding: 15px 80px;
            cursor: pointer;
        }
    }

    .dialogMask {
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 9999;
        background: rgba(55, 55, 55, 0.6);
    }
}
</style>

<script>
import ConfirmPassword from "./alert/confirmPassword"
import EditPasswordComplete from "./alert/editPasswordComplete"


export default {
    name: "modifyPassword",
    components: {
        ConfirmPassword,
        EditPasswordComplete
    },
    data: () => {
        return {
            oldPassword: "",
            oldPasswordType: "password",
            oldPasswordShow: false,
            newPassword: "",
            newPasswordType: "password",
            newPasswordShow: false,
            confirmPassword: "",
            confirmPasswordType: "password",
            confirmPasswordShow: false,
            background: "background: #A6AAAD",
            modifyPasswordShow: false,
            yesShow: false
        }
    },
    created() {},
    mounted() {},
    methods: {
        backClick() {
            this.$router.go(-1)
        },
        passwordChange() {
            if (this.oldPassword != "" && this.newPassword != "" && this.confirmPassword != "" && this.newPassword == this.confirmPassword) {
                this.background = "background: #330000"
            } else {
                this.background = "background: #A6AAAD"
            }
        },
        btnClick() {
            if (this.oldPassword != "" && this.newPassword != "" && this.confirmPassword != "" && this.newPassword == this.confirmPassword) {
                this.modifyPasswordShow = true
            }
        },
        handleClose() {
            this.modifyPasswordShow = false
        },
        deleteNo(val) {
            this.modifyPasswordShow = val
        },
        deleteYes(val) {
            this.modifyPasswordShow = val
            this.yesShow = true
        }
    }
}
</script>
