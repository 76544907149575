<template>
    <div class="edit">
        <div><img src="@/assets/images/personal/success.png" /></div>
        <span>Successfully modified</span>
        <button @click="btnClick">Log in again</button>
    </div>
</template>

<style scoped>
.edit {
    width: calc(23% - 40px);
    padding: 0 20px 20px;
    position: fixed;
    top: 35vh;
    margin: 0 auto;
    background: #fff;
    z-index: 9999999;
    animation: dialog 1s;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.edit > div{
    padding-top: 60px;
}
.edit > span{
    padding: 25px 0 60px;
    font-size: 14px;
    font-family: Montserrat,SF-UI;
    font-weight: 400;
    color: #330000;
}
.edit > button{
    width: 100%;
    outline: none;
    border: none;
    background: #330000;
    padding: 16px 0;
    text-align: center;
    font-size: 16px;
    font-family: Montserrat,SF-UI;
    font-weight: bold;
    color: #FFFFFF;
    cursor: pointer;
}
@keyframes dialog {
    form {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
</style>

<script>
export default {
	name: "editPasswordComplete",
	components: {
        
	},
	data: () => {
		return {
		}
	},
	watch: {},
	created() {
	},
	mounted() {
	},
	methods: {
        btnClick() {
            this.$router.push({ path: '/login' });
        }
	}
}
</script>