<template>
    <div class="edit">
        <h5>Confirm password?</h5>
        <div>
            <button class="no" @click="noClick">NO</button>
            <button class="yes" @click="yesClick">YES</button>
        </div>
    </div>
</template>

<script>
import { passWord } from "@/api/member/security"

export default {
    name: "confirmPassword",
	components: {

	},
    props: ["oldPassword", "newPassword", "confirmPassword"],
	data: () => {
		return {
            edit: false
		}
	},
    created() {
        // console.log("确认密码")
    },
	methods: {
        noClick() {
            this.$emit("deteleNO", this.edit);
        },
        yesClick() {
            // if(this.newPassword)
            passWord({ old_password: this.oldPassword, new_password: this.newPassword }).then( res => {
                // console.log(res)
                if(res.code == 0 && res.data){
                    this.$message.success("Successfully modified");
                    this.$emit("deteleYes", this.edit);
                }
            }).catch( err => {
                this.$message.success("Modification failed");
                this.$emit("deteleYes", this.edit);
            })
        }
	}
}
</script>

<style lang="scss" scoped>
.edit {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;

    h5 {
        font-size: 14px;
        font-family: Montserrat,SF-UI;
        // font-weight: 400;
        color: #330000;
        padding-bottom: 40px;
    }
    > div {
        display: flex;
        justify-content: space-between;

        > button {
            width: calc(50% - 11px);
            outline: none;
            border: none;
            cursor: pointer;
            padding: 15px 40px;
            font-size: 16px;
            font-family: Montserrat,SF-UI;
            font-weight: bold;
            border: 1px solid #330000;
        }
        .no{
            background: #330000;
            color: #FFFFFF;
        }
        .yes{
            background: #fff;
            color: #330000;
        }
    }
}
</style>